




























































































































































import { Values } from 'vue-i18n';
import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { Ax } from '@/utils';
import { Comp, Dict, Utils } from '../types';
import PaginationPages from '../components/PaginationPages.vue';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IRow extends Comp.TableRow<Dict.CivilPosition> {}


const modelChangeEvent = 'change';
const hasDifference = (items1: Dict.CivilPosition[], items2: Dict.CivilPosition[]): boolean => {
    if (items1.length !== items2.length) {
        return true;
    }
    if (items1.length === 0) {
        return false;
    }

    const ids1 = items1.map(item => item.id).sort();
    const ids2 = items2.map(item => item.id).sort();

    for (let i = 0; i < ids1.length; i++) {
        if (ids1[i] !== ids2[i]) {
            return true;
        }
    }

    return false;
};
const itemsPerPageVariants: Array<Comp.DropdownItemDef<number>> = [
    { value: 10, text: '10' },
    { value: 25, text: '25' },
    { value: 50, text: '50' },
    { value: 100, text: '100' }
];


@Component({
    components: {
        Pagination: PaginationPages
    }
})
export default class CivilPositionSelection extends Vue {
    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        this.selectedItems = [...this.value];

        this.$watch('value', () => {
            if (hasDifference(this.value, this.selectedItems)) {
                this.selectedItems = [...this.value];
            }
        });

        this.$watch('multiple', () => {
            if ((!this.multiple) && (this.selectedItems.length > 1)) {
                this.selectedItems = [this.selectedItems[0]];
            }
        });

        this.$watch('selectedIds', () => {
            this.rerender();
            if (hasDifference(this.value, this.selectedItems)) {
                this.$emit(modelChangeEvent, [...this.selectedItems]);
            }
        });

        this.$watch('itemsPerPage', () => {
            this.page = 0;
            this.reload();
        });

        this.$watch('searchText', () => {
            this.reload();
        });

        this.$watch('searchFuncBlock', () => {
            this.reload();
        });

        this.$watch('searchEchelon', () => {
            this.reload();
        });

        this.$watch('searchActivityField', () => {
            this.reload();
        });

        this.$watch('searchLevel', () => {
            this.reload();
        });
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        this.reload();
    }
    // endregion


    // region Модель, свойства
    @Model(modelChangeEvent, {
        type: Array,
        required: false,
        default: () => []
    })
    public readonly value!: Dict.CivilPosition[];

    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly multiple!: boolean;
    // endregion


    // region Утилиты
    private getTranslate(key: string, values?: Values): string {
        return String(this.$t(`modules.budget.staffing_table.*CivilPositionSelection*.${key}`, values));
    }

    private getFieldTranslate(i18nKey: string): string {
        return this.getTranslate(`table_fields.${i18nKey}`)
    };

    private getEnumTranslate(enumName: string, value: string): string {
        return String(this.$t(`modules.budget.staffing_table._enum.${enumName}.${value}`));
    }

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }


    // noinspection JSMethodCanBeStatic
    private getFuncBlockText(item: Dict.CivilPosition): string | null {
        if (item.funcBlock) {
            return item.funcBlock;
        }
        return null;
    }

    // noinspection JSMethodCanBeStatic
    private getEchelonText(item: Dict.CivilPosition): string | null {
        if ((item.level) && (item.level.parent)) {
            return item.level.parent.code;
        }
        return null;
    }

    // noinspection JSMethodCanBeStatic
    private getLevelText(item: Dict.CivilPosition): string | null {
        if (item.level) {
            return (item.levelNumber !== null) ? item.level.code + `  (${item.levelNumber})` : item.level.code;
        }
        return null;
    }

    private getItemFullText(item: Dict.CivilPosition): string {
        let result = '';
        const appendCode = (code: string | number | null) => {
            if (code === null) {
                return;
            }
            if (result.length > 0) {
                result += ' :: ';
            }
            result += code;
        };


        appendCode(item.levelNumber);

        if (result.length > 0) {
            result += ' - ';
        }

        if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
            result += item.nameKk;
        } else {
            result += item.nameRu;
        }

        return result;
    }

    private getItemText(item: Dict.CivilPosition): string {
        const result = this.getItemFullText(item);
        if (result.length > 50) {
            return result.substring(0, 47) + '...';
        }
        return result;
    }

    private getPosLevelText(posLevel: Dict.BudgetLevel | null): string | null {
        if (posLevel === null) {
            return null;
        }

        let title: string;
        if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
            title = posLevel.nameKk;
        } else {
            title = posLevel.nameRu;
        }

        return `${posLevel.code} - ${title}`;
    }
    // endregion


    // region Выбранные элементы
    private selectedItems: Dict.CivilPosition[] = [];

    private get selectedIds(): Array<number | null> {
        return this.selectedItems.map(item => item.id);
    }

    private unselect(item: Dict.CivilPosition) {
        const index = this.selectedIds.indexOf(item.id);
        if (index >= 0) {
            this.selectedItems.splice(index, 1);
        }
    }

    private toggleRowSelection(row: IRow) {
        const index = this.selectedIds.indexOf(row.data.id);
        if (index >= 0) {
            this.selectedItems.splice(index, 1);
        } else if (this.multiple) {
            this.selectedItems.push(row.data);
        } else {
            this.selectedItems = [row.data];
        }
    }
    // endregion

    private optionsActivityField = [
        { text: 'Все', value: null },
        { text: 'Все сферы деятельности', value: 'ALL' },
        { text: 'Прочие сферы (кроме здравоохранения и образования)', value: 'ALL_EXCEPT_HEALTH_AND_EDUCATION' },
        { text: 'Образование', value: 'EDUCATION' },
        { text: 'Здравоохранение', value: 'HEALTH' },
        { text: 'Здравоохранение; Образование', value: 'HEALTH_AND_EDUCATION' },
        { text: 'Спорт', value: 'SPORT' }
    ];

    private optionsCivilFuncBlock = [
        { text: 'Не определён', value: null },
        { text: 'A', value: 'A' },
        { text: 'B', value: 'B' },
        { text: 'C', value: 'C' },
        { text: 'D', value: 'D' },
    ];

    // region Навигация по страницам
    private itemsPerPageVariants: Array<Comp.DropdownItemDef<number>> = [...itemsPerPageVariants];

    private itemsPerPage = 25;

    private page = 0;

    private totalItems = 0;

    private onPageChanged(page: number) {
        this.page = page;
        this.reload();
    }
    // endregion


    private searchText = '';
    private searchEchelon = '';
    private searchFuncBlock = null;
    private searchActivityField = null;
    private searchLevel = null;


    // region Строки таблицы
    private get fields(): Comp.TableFieldDef[] {
        const dataField = (dataKey: string, i18nKey: string): Comp.TableFieldDef => {
            return {
                key: `data.${dataKey}`,
                label: this.getTranslate(`table_fields.${i18nKey}`)
            };
        };

        return [
            // ID
            dataField('id', 'id'),

            // Функциональный блок
            dataField('funcBlock', 'func_block'),

            // Звено
            dataField('echelon', 'echelon'),

            // Ступень
            dataField('level', 'level'),

            // Номер в ступени
            // dataField('levelNumber', 'level_number'),

            // Название на казахском
            dataField('nameKk', 'name_kk'),

            // Название на русском
            dataField('nameRu', 'name_ru'),

            // // Уровень должности
            // ( Временно скрыть для - гражданской должности )
            // dataField('posLevel', 'pos_level'),

            // Сфера деятельности
            dataField('activityField', 'activity_field')
        ];
    }

    private loading = false;

    private rows: IRow[] = [];

    private renderScheduled = false;

    private rerender() {
        if (this.renderScheduled) {
            return;
        }
        this.renderScheduled = true;
        this.$nextTick(() => {
            this.renderScheduled = false;

            this.rows.forEach(row => {
                row.selected = (this.selectedIds.includes(row.data.id));
                if (row.selected) {
                    row._rowVariant = 'primary';
                } else {
                    row._rowVariant = null;
                }
            });

            this.rows = [...this.rows];
        });
    }

    private reload() {
        if (this.loading) {
            console.warn('Cannot reload rows - another loading is running');
            return;
        }

        this.loading = true;
        this.rows = [];
        Ax<Utils.PaginationList<Dict.CivilPosition>>(
            {
                url: '/api/budget/staffing_table/civil-positions',
                method: 'GET',
                params: {
                    'search-text': this.searchText,
                    'search-funcBlock': this.searchFuncBlock,
                    'search-echelon': this.searchEchelon,
                    'search-activityField': this.searchActivityField,
                    'search-level': this.searchLevel,
                    'items-per-page': this.itemsPerPage,
                    'page': this.page
                },
                responseType: 'json'
            },
            list => {
                this.page = list.page;
                this.totalItems = list.itemCount;

                const rows: IRow[] = [];

                list.items.forEach(item => {
                    const row: IRow = {
                        id: String(item.id),
                        data: item,
                        original: null,
                        selected: this.selectedIds.includes(item.id),
                        changed: false,
                        invalid: false,
                        _rowVariant: (this.selectedIds.includes(item.id) ? 'primary' : null),
                        errors: {},
                        inputValues: {}
                    };
                    rows.push(row);
                });

                this.rows = rows;
            },
            error => {
                this.toast('danger', this.getTranslate('error.cannot_load_positions'), error.toString());
            },
            () => {
                this.loading = false;
            }
        );
    }
    // endregion
}
